<template>
  <v-row class="flex-column px-5 px-lg-9">
    <!-- <v-col cols="12" class="d-flex flex-column">
      <h3 class="default--text">{{ $t("leaflet.rivista.title") }}</h3>
      <p>{{ $t("leaflet.rivista.subtitle") }}</p>
      <div class="filter-filter pa-4 grey rounded-md">
        <v-select
          v-model="magazineDate"
          dense
          background-color="white"
          hide-details
          return-object
          :label="$t('leaflet.rivista.filter.placeholder')"
          outlined
        ></v-select>
      </div>
    </v-col> -->
    <v-col cols="12">
      <v-row class="flex-wrap mt-4">
        <v-col
          class="d-flex flex-column"
          cols="auto"
          v-for="magazine in magazines?.slice(1)"
          :key="magazine.id"
        >
          <v-card :to="`/volantini/${magazine.leafletId}`">
            <h6 class="font-weight-regular text-capitalize">
              {{ $dayjs(magazine.fromDate).format("MMMM YYYY") }}
            </h6>
            <v-img
              class="magazine-cover rounded-md"
              contain
              onerror="this.onerror=null;this.src='/no-icon.png'"
              :src="`${magazine?.baseLocation}${magazine?.firstPagePreview}`"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.magazine-cover {
  max-width: 200px;
}
.filter-filter {
  .v-input__control .v-input__slot {
    border: 0 !important;
  }
  .v-input__slot {
    height: 44px !important;
  }

  .v-text-field--outlined fieldset {
    color: white !important;
    border-radius: 8px;
  }
}
</style>
<script>
import leafletService from "@/commons/service/leafletService";
export default {
  name: "LeafletMagazine",
  data() {
    return {
      magazines: null,
      magazineDate: null,
      magazinesLoading: false
    };
  },

  methods: {
    async fetchMagazines() {
      this.magazinesLoading = true;
      try {
        this.magazines = await leafletService.search({
          parent_leaflet_type_id: 2
        });

        if (this.magazines && this.magazines.length > 0) {
          this.magazines.sort((a, b) => {
            if (!a.fromDate) return -1;
            if (!b.fromDate) return 1;
            return new Date(b.fromDate) - new Date(a.fromDate);
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.magazinesLoading = false;
      }
    }
  },
  async created() {
    await this.fetchMagazines();
  }
};
</script>
