<template>
  <div>
    <v-list class="py-10" nav v-if="!loading">
      <v-list-item
        v-for="(warehouse, index) in warehouseList"
        :key="`warehouse_${index}`"
        two-line
        dense
        @click="goToWarehouse(warehouse)"
      >
        <v-list-item-content>
          <v-list-item-title
            class="default--text text-capitalize text-left font-weight-medium mb-2"
          >
            {{ warehouse.address.addressName || warehouse.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="defaul--text warehouse-address">
            {{ warehouse.address.address1 }},{{
              warehouse.address.addressNumber
            }}
            {{ warehouse.address.city }} ({{ warehouse.address.province }})
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-icon class="font-weight-bold" color="primary">$arrowForward</v-icon>
      </v-list-item>
    </v-list>
    <div v-else>
      <v-skeleton-loader
        v-for="index in 6"
        :key="index"
        type="list-item-three-line"
      ></v-skeleton-loader>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import addressService from "@/commons/service/addressService";
export default {
  name: "LeafletWarehouseList",
  data() {
    return {
      warehouseList: null,
      loading: false
    };
  },
  props: {
    leafletTypeId: { type: Number, required: true }
  },
  computed: {},
  methods: {
    goToWarehouse(warehouse) {
      this.$router.push({
        name: "WarehouseDetail",
        params: { slug: warehouse.slug }
      });

      document.querySelector(".vuedl-layout__closeBtn").click();
    },
    async findWarehouse() {
      this.loading = true;
      try {
        let data = await addressService.findWarehouse();
        console.log("all wh", data.warehouses);
        if (data) {
          this.warehouseList = data.warehouses.filter(warehouse =>
            warehouse.metaData?.warehouse_detail?.LEAFLET_TYPE?.includes(
              this.leafletTypeId.toString()
            )
          );
        }
        this.loading = false;
        console.log("wh", this.warehouseList);
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.general")
        });
        return null;
      }
    }
  },
  async created() {
    await this.findWarehouse();
  },
  mounted() {}
};
</script>
